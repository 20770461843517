<template>
  <div>
    <div class="card" v-if="!courses">
      <div class="card-body">
        <p class="center">
          <i class="el-icon el-icon-loading"></i>
        </p>
        <p class="text-center">
          <CSpinner component="span" size="sm" aria-hidden="true" />
        </p>
        <p class="text-center">
          Einen Moment, wir laden die Kursdaten.
        </p>
      </div>
    </div>

    <div class="card" v-if="courses && courses.length === 0">
      <div class="card-body">
        <p class="text-center">
          Aktuell haben wir keine Kurse dieser Kategorie ausgeschrieben.
        </p>
      </div>
    </div>

    <div v-if="filteredcourses" class="row">
      <div class="col-12">
        <div class="card" v-for="course in filteredcourses"
             :key="course.id"
             :class="{'centered': courses.length === 1}">
          <div class="form card-body row">
            <div class="col-md-4">
              <p class="no padding margin">
                <i class="fa fa-folder-open-o"></i>
                <b>{{ course.type_name }}</b>
              </p>
              <p class="no padding margin">
                {{ course.name }}
              </p>
            </div>

            <div v-if="!showSignup" class="col-md-4">
              <p v-if="course.lections && course.lections.length > 0" class="no padding margin">
                <b>{{ course.lections[0].location_name }}</b><br />
              </p>
              <p v-for="lection in course.lections" :key="lection.lection_id" class="no padding margin">
                {{ formatDate(lection.begin) }} {{ formatTime(lection.begin) }} - {{ formatTime(lection.end) }}
              </p>
            </div>

            <div v-if="showSignup" class="col-md-4">
              <p v-if="course.lections && course.lections.length > 0" class="no padding margin">
                <b>{{ course.lections[0].location_name }}</b><br />
              </p>
              <p v-for="lection in course.lections" :key="lection.lection_id" class="no padding margin">
                {{ formatDate(lection.begin) }} {{ formatTime(lection.begin) }}
                - {{ formatTime(lection.end) }}<br>
              </p>
            </div>

            <div class="col-md-4 col-center">
              <i class="fa fa-user-circle green" v-if="!course.full"></i>
              <i class="fa fa-user-circle-o red" v-if="course.full"></i> {{ course.places }} Plätze<br>
              <i class="fa fa-hourglass-half"></i> {{ countLections(course.lections) }} Lektion(en)

              <p v-if="course.full" class="no padding margin">
                <i class="fa fa-info-circle"></i>
                <b>Dieser Kurs ist leider ausgebucht.</b>
              </p>

              <p v-if="!course.full" class="no padding margin">
                <i class="fa fa-usd"></i>CHF {{ course.price }}
                <!-- quick and dirty: show "price is per part" for moto course -->
                <span v-if="course.type_name.includes('Moto')">pro Kursteil</span>
              </p>
            </div>

            <div v-if="showSignup" class="col-12 text-center">
              <p v-if="!course.full">
                <router-link
                    :to="{name: 'signup', params: {courseId: course.id}}"
                    class="btn btn-primary">
                  &raquo; Jetzt anmelden
                </router-link>
              </p>
              <p v-else-if="course.full">
                <button class="btn btn-secondary disabled">
                  Kurs ist ausgebucht
                </button>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns';
import de from 'date-fns/locale/de';

export default {
  name: 'CourseListComponent',
  props: {
    courses: {
      required: true
    },
    number: {
      required: false,
      default: '0'
    },
    showSignup: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      filteredcourses: [],
      run_once: true,
      addition_needed: false,
      showModal: false,
      showUpsell: false,
      form: {
        checkedcourse: [],
        firstName: null,
        lastName: null,
        street: null,
        city: null,
        birthday: null,
        regNumber: null,
        regValidUntil: null,
        mail: null,
        phone: null,
        moto_kw: null,
        moto_ccm: null,
        moto_manufacturer: null,
        moto_model: null
      }
    };
  },
  updated() {
    this.checkone();
    this.filtercourse();
  },
  methods: {
    filtercourse: function () {
      let self = this;
      if (self.number > 0) {
        if (self.run_once) {
          self.run_once = false;
          self.courses.forEach(function callback(currentValue, index) {
            if (currentValue.typeId == self.number) {
              self.filteredcourses.push(currentValue);
            }
          });
        }
        setTimeout(function () {
          self.run_once = true;
        }.bind(self), 4000);
      } else {
        self.filteredcourses = self.courses;
      }
    },
    checkone: function () {
      let self = this;
      if (self.courses != undefined && self.form.checkedcourse.length === 0) {
        self.form.checkedcourse = self.courses[0].lections[0].lection_id;
      }
    },
    formatDate: function (date) {
      return format(date, 'dd, DD.MM.YY', {locale: de});
    },
    formatTime: function (date) {
      return format(date, 'HH:mm', {locale: de});
    },
    countLections: function (lections) {
      let count = 0;
      lections.forEach(x => {
        count += x.lection_count;
      });

      return count;
    }
  }
};
</script>

<style lang="scss" scoped>
.courses {
  flex-direction: column;

  .course {
    padding: 0.5em;
  }
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.3;
}

input[type="checkbox"] + label:before {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  border: 2px solid #ddd;
  background-color: #EEE;
  content: "";
  margin-right: 15px;
  transition: background-color 0.5s linear;
}

input[type="checkbox"] + label:after {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background-color: #fff;
  content: "";
  transition: margin 0.1s linear;
  box-shadow: 0 0 5px #aaa;
  position: absolute;
  left: 2px;
  top: 2px;
}

input[type="checkbox"]:checked + label:before {
  background-color: #2b8718;
}

input[type="checkbox"]:checked + label:after {
  margin: 0 0 0 20px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.3;
}

input[type="radio"] + label:before {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  border: 2px solid #ddd;
  background-color: #EEE;
  content: "";
  margin-right: 15px;
  transition: background-color 0.5s linear;
}

input[type="radio"] + label:after {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background-color: #fff;
  content: "";
  transition: margin 0.1s linear;
  box-shadow: 0 0 5px #aaa;
  position: absolute;
  left: 2px;
  top: 2px;
}

input[type="radio"]:checked + label:before {
  background-color: #2b8718;
}

input[type="radio"]:checked + label:after {
  margin: 0 0 0 20px;
}

div.form {
  padding: 0.5rem;

  div.row div {
    padding: 0.3em 0.3em 0.1em;
  }

  div {
    margin-bottom: 0.3rem;
  }

  @media (max-device-width: 481px) {
    div:first-of-type {
      margin-top: 0.5rem;
    }

    div:last-of-type {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
