import axios from 'axios';
import config from '@/config';

export default {
  getCourseList(filter) {
    if (filter) {
      return axios
        .get(config.path(null, '/public/gsf/course', 'filter=' + filter));
    }

    return axios
      .get(config.path(null, '/public/gsf/course'));
  },
  getCourse(courseId) {
    return axios
      .get(config.path(null, '/public/gsf/course/' + courseId));
  },
  getParentCourses(courses, date) {
    courses = courses ? courses.replace(/[- }{]/g, '') : '';

    return axios
      .get(config.path(null, '/public/gsf/coursebytype/?ids=' + courses + '&date=' + date));
  },
  getChildCourses(course, date) {
    return axios
      .get(config.path(null, '/public/gsf/coursechildbytype/?ids=' + course + '&date=' + date));
  },
  addParticipant(courseId, formData) {
    return axios
      .post(config.path(null, '/public/gsf/course/participant/' + courseId), formData);
  }
};
