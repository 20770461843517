export default {
  path: function (api, path, query) {
    let postfix = process.env.URL_POSTFIX
    let appendedQuery = ''
    if (typeof query !== 'undefined' && query.length > 0) {
      appendedQuery = '?' + query //+ '&' + postfix
    } else if (typeof postfix === 'string' && postfix.length > 0) {
      appendedQuery = '?' + postfix
    }

    // return 'http://localhost:8088/app_dev.php' + path + appendedQuery
    return '//app.gruetter-fahrschule.ch' + path + appendedQuery
  }
}
